
html, body, #root, .x-wrapper{
    width: 100%;
    height: 100%;
    margin: 0px;
    display: contents;
}

.carousel-caption {
    border-color: rgba(0, 0, 0, .5);
}

h1 {
    margin-bottom: 1em;
}
.modal-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: 500;
    top: 0;
    background-color: rgba(0,0,0,.35);
}
.sbutton {
    border-style: none;
    color: black;
    cursor: pointer;
    position: relative;
    right: 33%;
    transform: translateY(50px);
    font-weight: bold;
    padding: 0.9375rem;
    justify-content: right;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    background-color: transparent;
    background-image: none;
}
.close-wrapper{
    display: flex;
    justify-content: right ;
}
.modal-wrapper {
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 25%;
  width: 100%;
  z-index: 1000;
}

.s-modal {
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 25%;
  width: 100%;
  z-index: 1000;
}

.gallery-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
}

.outter-wrapper {
    padding: .5rem  2rem;
}
.dashed {
    border-style: dashed;

}


.card-wrapper {
    display: flex;  
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235);
    border-radius: 10px;
    position: relative;
    z-index: 2;
}
.asset-anchor {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.asset-anchor-wrapper {
    height: 322px;
    width: 322px;
}

.asset-anchor-wrapper-inner {
    min-height: inherit;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    align-items: center;
}

.asset-anchor-wrapper-inner-2 {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    
}

.img-wrapper {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.details-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    cursor: pointer;
}

.details-title-wrapper {
    padding: 12px;
    justify-content: space-between;
    display: flex;
}

.detail-title-left-wrapper {
    margin-right: 12px;
    min-width: 0px;
    width: 60%;
}

.details-title-right-wrapper {
    width: 40%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}
.details-title-left-wrapper-inner-1 {
    display: flex;
    align-items: center;
    color: rgb(112, 122, 131);
    font-weight: 500;
    font-size: 12px;
}

.details-title-left-wrapper-inner-2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    color: rgb(53, 56, 64);
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: 600;
    text-align: left;
}
.details-assets-right-wrapper-inner-1 {
    flex: 1 0 0%;
    align-items: flex-end;
    flex-direction: column;
    display: flex;
}

.sell-wrapper {
    padding: .5rem
}
.span-price {
    font-weight: 400;
    font-size: 12px;
    color: rgb(112, 122, 131);
}

.price-wrapper {
    font-size: 14px;
    height: initial;
    justify-content: flex-end;
    max-width: 13ch;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    color: rgb(53, 56, 64);
    width: fit-content;
}


.near-symbol {
    align-items: center;
    display: flex;
    font-weight: 600;
    color: rgb(53, 56, 64);
    width: fit-content;
    max-width: 100%;
}
.near-logo {
    height: 14px;
    width: 16px;
}
.price {
    margin-left: 0.3em;
    width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.s-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0.9375rem 1.875rem 0.9375rem;
}

.s-title {
    margin-bottom: 0.3125rem;
}

.s-description {
  color: #C1C1C1;
  text-align: center;
}
.market-wrapper {
    display: flex;
    justify-content: center;
}
.market-inner-wrapper {
    
    padding-top: 1rem;
    padding-bottom: 1rem
}

.market-result-wrapper {
    display: flex;
    justify-content: center;
    width: fit-content;
}
.market-header {
    text-align: center;
    width: 100%;
}
.text-center {
    text-align: center;
}

.pb-1 {
    padding-bottom: 1rem;
}
.top-header {
    width: 100%;
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    max-width: 100vw;
    height: 72px;
    top: 0px;
    position: sticky;
    z-index: 110;
    transition: top 0.5s ease 0s;
    background-color: rgb(255, 255, 255);
}


.menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1rem;
}
.nav {

}
.navbar{

}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.username {
    padding: .2rem;
}


.navbar-ul{
    display: flex
}

 .wrapper {
     width: auto;
     padding: 1rem;
 }
.welcome-wrapper {
    display: block;
}
.welcome-text {
    font-size: 1.2rem;
    font-weight: 500;
}
.main-wrapper {
    margin: 0 auto;
    text-align: center;
}
.navbar-li{
    padding: 0rem;
}

ul {
    list-style-type: none;
    list-style: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.navbar-left {
    width: auto;
}
.navbar-right {

}
.outform-wrapper {
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0rem;
    margin: 0rem;
}

.form-wrapper {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(229, 227, 221);
    border-radius: 4px;
    box-shadow: none;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    box-sizing: border-box;
    margin: 0px auto;
    max-width: 32rem;
    width: 100%;
}

.box-wrapper {
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding-bottom: .3rem;
    margin: 0rem;
}
.box-in-wrapper {
    position: relative;
}

.label{
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0rem;
    margin: 0rem;
}
.form-in-wrapper {
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 1.5rem;
    margin: 0rem;
}
.input-wrapper{
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
    position: relative;
    margin-top: 0.5rem;
}
.input-box {
    resize: none;
    min-width: 0px;
    width: 100%;
    font-weight: normal;
    appearance: none;
    background: rgb(245, 244, 242);
    border: 1px solid rgb(229, 227, 221);
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(36, 30, 18);
    height: 3rem;
    outline: none;
    padding: 0.75rem 1rem;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border-radius: 4px;
    font-size: 1rem !important;
}
.mint-text {
    color: rgb(36, 30, 18);
    font-family: Walsheim, sans-serif;
    position: relative;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    text-align: center;
    font-weight: 400 !important;
    margin: 1em 0px !important;
    font-size: 1.3125rem !important;
    line-height: 1.25 !important
}





.form-btn-wrapper {
    margin: 1rem 0rem
}
.nav-items {

}

.container {
    margin: 0 auto;
}

.flex {
    display: flex;
}


.pl {
    padding-left:.2rem
}

.pr {
    padding-right:.2rem
}
.pr-2 {
    padding-right: .5rem;
}
.pt {
    padding-top: 0.7rem;
}

.pt-3 {
    padding-top: 0.2rem;
}

@font-face {
    font-family: 'Inter';
    src:  url('./fonts/static/Inter-Regular.ttf') format('ttf'),
    url('./fonts/static/Inter-Medium.ttf') format('ttf');
}

body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.important {
    font-size: 1.9rem;
    color: #FF585D;
}



a {
    text-decoration: none;
}


.log-link {
    background-color: white;
    color: rgb(36, 30, 18);
    margin: 0em;
    border-style: none;
    border-color: white;
    padding: .65rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    border: 1px solid #FF585D;
    border-radius: 9999px;
    cursor: pointer;
}


.log-link:hover {
    color: #fff;
    border-color: #fff;
    background-color: #ff424d;
}

body {
    overflow-x: hidden;
}

:root {
    --primary-light: #8abdff;
    --primary: #6d5dfc;
    --primary-dark: #5b0eeb;
    --white: #FFFFFF;
    --greyLight-1: #E4EBF5;
    --greyLight-2: #c8d0e7;
    --greyLight-3: #bec8e4;
    --greyDark: #9baacf;
}

.card-title {
    overflow-y: hidden;
}

/* variables */
body {
    background: #e0e5ec;
    min-height: 100vh;
}

.wrapper {
    margin: 60px auto;
}

.neumorphism {
    background-color: #e0e5ec;
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
}

.card-w {
    margin-bottom: 20px;
}

.accordion-button {
    background-color: #e0e5ec;
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
}

    .accordion-button:not(.collapsed) {
        color: var(--primary);
        background-color: #e0e5ec;
        box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    }

    .accordion-button:focus {
        z-index: 3;
        border-color: var(--primary-light);
        box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    }

.card-w .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    position: relative;
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

    .card-w .card .card__text {
        text-align: center;
    }

/* inset */
.card.inset {
    box-shadow: inset 3px 3px 7px rgba(136, 165, 191, 0.48), inset -3px -3px 7px #FFFFFF;
}

    .card.inset i {
        text-shadow: 1px 1px 1px #d6e1ef99, 0 0 0 #000, 1px 1px 1px #d6e1ef00;
    }

.card.active {
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5), inset 3px 3px 7px rgba(136, 165, 191, 0.48), inset -3px -3px 7px #FFFFFF;
    background: linear-gradient(318.32deg, rgba(163, 177, 198, 0.1) 0%, rgba(163, 177, 198, 0.1) 55%, rgba(163, 177, 198, 0.25) 100%);
}

.btn-outline-primary {
    color: var(--greyDark);
    border: none !important;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
}

    .btn-outline-primary:disabled {
        color: var(--greyLight-3);
    }

a {
    color: var(--greyDark);
    text-decoration: none;
}

.btn-outline-primary:active {
    box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2), inset -0.2rem -0.2rem 0.5rem var(--white);
}

.btn-outline-primary:hover {
    color: var(--primary);
    background-color: transparent;
}

a:hover {
    color: var(--primary);
    text-decoration: none;
}

.mintbutton {
}

.full-width {
    width: 15rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    font-size: 1rem;
    padding-left: 1rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2), inset -0.2rem -0.2rem 0.5rem var(--white);
    background: none;
    font-family: inherit;
    color: var(--greyDark);
}

.full-width:focus {
    outline: none;
    box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
}

.footer {
    padding-top: 10rem;
}
.carousel-inner, .carousel-item {
    max-height: 400px;
}

@media only screen and (max-width: 600px) {
    .sbutton {

        position: relative;
        right: 0;
        transform: translateY(50px);

    }

    .last-card {
        margin-top: 2em;
    }
}
